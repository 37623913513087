import React from "react";
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Layout";

// const stripePromise = loadStripe('pk_live_51JayKeJPNl8DCuX4tlQpIvywSMk6P3Xh5lTL9xXgGS09ZcKLGhQXmvei4EKcnjQGYICzRlZx1PNFtVfIDLIFSYhv00wTWHw8Fg');

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>
    {/* <Elements stripe={stripePromise}> */}
    {element}
    {/* </Elements> */}
    </GlobalProvider>
);
